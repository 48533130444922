import React, { createContext, useContext, useEffect, useMemo, useReducer } from 'react'
import { updateEntryApi } from '../api/mobile/entriesApi'
import { mapDescendantRecords, updateEntryHelper } from '../utilities/mobileRecordHelper'
import { useParams } from "react-router-dom"
import { getRecord } from "../api/mobile/recordsApi"

/* Actions */
const UPDATE_STATE = 'UPDATE_STATE'
const INIT = 'INIT'
const UPDATE_ENTRY = 'UPDATE_ENTRY'

/* Initial State */

const initialState = {
  mobileRecord: {},
  descendantRecords: {},
  formTemplates: {}
}

const initState = ({ mobileRecord, descendantRecords, formTemplates }) => ({
  ...initialState,
  mobileRecord: mobileRecord,
  descendantRecords: mapDescendantRecords(descendantRecords),
  formTemplates: formTemplates
})

/* Reducer */
const mobileRecordReducer = (state, action) => {
  switch (action?.type) {
    case UPDATE_STATE:
      return { ...state, ...action.payload }
    case INIT:
      return { ...state, ...initState(action.initState) }
    case UPDATE_ENTRY:
      const {
        updatedRecord,
        updatedDescendantRecords
      } = updateEntryHelper(state.mobileRecord, state.descendantRecords, action.payload.entry, action.payload.map)
      return { ...state, mobileRecord: updatedRecord, descendantRecords: updatedDescendantRecords }
    default:
      return state
  }
}

/* Contexts */

const MobileRecordContext = createContext(initialState)

const MobileRecordApiContext = createContext({
  updateState: () => {},
  updateEntry: () => {}
})

/* Provider */
export const MobileRecordProvider = ({ children }) => {
  const { projectId, noteId } = useParams()
  const [state, dispatch] = useReducer(mobileRecordReducer, initialState)

  const api = useMemo(() => {
    const updateState = (field, value) => dispatch({ type: UPDATE_STATE, payload: { [field]: value } })

    const updateEntry = (entry, map) => {
      updateEntryApi(entry).then(res => { dispatch({ type: UPDATE_ENTRY, payload: { entry: entry, map: map } }) })
    }

    return {
      updateState,
      updateEntry
    }
  }, [])

  useEffect(() => {
    noteId && getRecord(projectId, noteId).then(res => {
      void dispatch({
        type: INIT,
        initState: {
          mobileRecord: res?.root_record,
          descendantRecords: res?.descendant_records,
          formTemplates: res?.mobile_forms
        }
      })
    })
  }, [noteId])

  return (
    <MobileRecordApiContext.Provider value={api}>
      <MobileRecordContext.Provider value={state}>
        {children}
      </MobileRecordContext.Provider>
    </MobileRecordApiContext.Provider>

  )
}

/* Custom Context Hooks */
export const useMobileRecordContext = () => useContext(MobileRecordContext)
export const useMobileRecordApi = () => useContext(MobileRecordApiContext)
