import { useCallback, useMemo } from "react";
import { daysOfWeek } from "../constants";
import { formatDateString, getWeekEnd } from "../utilities/dateHelpers";
import { optionizeTasks, projectOptionsValues, reducePortfolios, reduceProjects } from "../utilities/timesheetHelpers";
import { pipe } from "../utilities/utils";

export const useTimesheetEntry = ({
  editEntry,
  projects,
  portfolios,
  tasks,
  weekStart,
  updateEditEntryState
}) => {
  const { project_id, portfolio_id, timesheet_task_id } = editEntry

  // Compute week range
  const formattedWeekStart = formatDateString(weekStart)
  const weekEnd = useMemo(() => pipe(getWeekEnd, formatDateString)(weekStart), [weekStart])

  // Compute select options
  const portfolioOptions = useMemo(() => reducePortfolios(portfolios), [portfolios])
  const [jobNumberOptions, projectOptions, historicalJobNumberOptions] = useMemo(() => reduceProjects(projects, portfolio_id), [projects, portfolio_id])
  const taskOptions = useMemo(() => optionizeTasks(tasks), [tasks])

  // Compute selected values
  const portfolioValue = useMemo(() => portfolioOptions.find(option => option.value === portfolio_id), [portfolio_id])
  const [jobNumberValue, projectValue, historicalJobNumberValue] = useMemo(() => projectOptionsValues(projects, project_id), [project_id, projects])
  const taskValue = useMemo(() => taskOptions.find(task => task.value === timesheet_task_id), [taskOptions, timesheet_task_id])

  // Handlers
  const updateField = useCallback((field, value) => updateEditEntryState(field, value), [updateEditEntryState])
  const updateProjectId = useCallback(event => updateField('project_id', event?.value), [updateField])

  // Day options
  const dayOptions = useMemo(() => daysOfWeek.map(day => ({
    label: day.charAt(0).toUpperCase() + day.slice(1), value: day
  })), [])

  return {
    formattedWeekStart,
    weekEnd,
    portfolioOptions,
    jobNumberOptions,
    projectOptions,
    historicalJobNumberOptions,
    taskOptions,
    portfolioValue,
    jobNumberValue,
    projectValue,
    historicalJobNumberValue,
    taskValue,
    updateField,
    updateProjectId,
    dayOptions,
  };
}
