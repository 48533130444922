import React, { useEffect, useMemo, useRef } from "react";
import { useTimesheetContext } from "../../../contexts/timesheetContext";
import { useTimeTableApi, useTimeTableContext } from "../../../contexts/timeTableContext";
import { useTimesheetEntry } from "../../../hooks/useTimesheetEntry";
import { getDateFromWeekStartAndDay } from "../../../utilities/dateHelpers";
import ModalWrapper from "../../modal/modalWrapper";
import RenderModal from "../../modal/renderModal";
import HiddenFields from "./hiddenFields";
import TimesheetModalHourInput from "./hourInput";
import SelectField from "./selectedField";

const TimesheetEntryModal = () => {
  const formRef = useRef();
  const { toggleState, updateEditEntryState, updateTimeEntry, createTimeEntry, deleteTimeEntry } = useTimeTableApi()
  const { userId, editModal, editEntry } = useTimeTableContext()
  const { projects, portfolios, tasks, weekStart } = useTimesheetContext()
  const { id, description, errors, hours, selectedDay, day_entry_id } = editEntry

  const {
    formattedWeekStart, weekEnd,
    portfolioOptions, jobNumberOptions, projectOptions, historicalJobNumberOptions, taskOptions,
    portfolioValue, jobNumberValue, projectValue, historicalJobNumberValue, taskValue,
    updateField, updateProjectId, dayOptions
  } = useTimesheetEntry({ userId, editEntry, projects, portfolios, tasks, weekStart, updateEditEntryState })

  useEffect(() => {
    if (selectedDay) {
      updateField("date", getDateFromWeekStartAndDay(weekStart, selectedDay));
    }
  }, [selectedDay, weekStart, updateField]);

  const onSubmitHandler = useMemo(() => id ? updateTimeEntry : createTimeEntry, [id])

  return (
    <>
      {editModal && (
        <RenderModal>
          <ModalWrapper
            heading={`${formattedWeekStart} - ${weekEnd}`}
            closeModal={() => toggleState("editModal")}
            onSend={() => onSubmitHandler(formRef)}
            submitButtonText="Submit"
          >
            <form ref={formRef} className="flex flex-col gap-5">
              <HiddenFields {...editEntry} userId={userId} />

              {/* Project Information */}
              <div className="flex flex-col lg:flex-row gap-4 w-full justify-between">
                <SelectField label="Job Number" name="job_number" options={jobNumberOptions} value={jobNumberValue} onChange={updateProjectId} required errors={errors} />
                <SelectField label="Historical Job Number" name="historical_job_number" options={historicalJobNumberOptions} value={historicalJobNumberValue} onChange={updateProjectId} />
              </div>
              <div className="flex flex-col lg:flex-row gap-4 w-full justify-between">
                <SelectField label="Portfolio" name="portfolio_id" options={portfolioOptions} value={portfolioValue} onChange={event => updateField("portfolio_id", event?.value)} />
                <SelectField label="Project" name="project_id" options={projectOptions} value={projectValue} onChange={updateProjectId} required errors={errors} />
              </div>

              {/* Task Selection */}
              <SelectField label="Task" options={taskOptions} name="timesheet_task_id" value={taskValue} onChange={({ value }) => updateField("timesheet_task_id", value)} required errors={errors} />

              {/* Hours/Days */}
              <a className="text-red-700 text-sm">*Only one day per entry</a>
              {(!selectedDay || !id) && (
                <SelectField label="Select Day" options={dayOptions} onChange={({ value }) => updateField("selectedDay", value)} />
              )}
              {selectedDay && (
                <TimesheetModalHourInput name={`${selectedDay.charAt(0).toUpperCase() + selectedDay.slice(1)} hours`} field="hours" value={hours || ""} />
              )}

              {/* Description */}
              <label htmlFor="description">
                Description
                <textarea
                  className="border-solid border-2 border-gray-300 rounded-lg h-full min-h-fit w-full relative p-3"
                  placeholder="Enter description..."
                  onChange={event => updateField("description", event.target.value)}
                  value={description || ""}
                  name="description"
                />
              </label>
            </form>

            {id && (
              <button onClick={() => deleteTimeEntry(day_entry_id, id)} className="bg-red-600 font-semibold px-4 py-2 text-white rounded mt-5">
                Delete Entry
              </button>
            )}
          </ModalWrapper>
        </RenderModal>
      )}
    </>
  );
};

export default TimesheetEntryModal;
