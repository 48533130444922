import React, { createContext, useContext, useEffect, useMemo, useReducer } from 'react'
import { useParams } from 'react-router-dom'
import { getActiveJobs } from '../api/jobsApi'
import { getProjectMobileRecords, importProjectMobileRecords } from "../api/mobile/recordsApi"
import { getProject } from '../api/projectApi'
import useInterval from '../hooks/use_interval'

/* Actions */
const UPDATE_STATE = 'UPDATE_STATE'
const SET_AWAITING_JOBS = 'SET_AWAITING_JOBS'

/* Initial State */
const initialState = {
  mobileRecords: [],
  loading: true,
  awaitingJobs: true,
}

/* Reducer */
const mobileRecordReducer = (state, action) => {
  switch (action?.type) {
    case UPDATE_STATE:
      return { ...state, ...action.payload }
    case SET_AWAITING_JOBS:
      return { ...state, awaitingJobs: action.payload }
    default:
      return state
  }
}

/* Contexts */
const MobileRecordsContext = createContext(initialState)

const MobileRecordsApiContext = createContext({
  setSyncEnabled: () => {},
})

/* Provider */
export const MobileRecordsProvider = ({ children }) => {
  const { projectId } = useParams()
  const [state, dispatch] = useReducer(mobileRecordReducer, initialState)

  const api = useMemo(() => {
    const updateState = (field, value) => dispatch({ type: UPDATE_STATE, payload: { [field]: value } })

    const syncMobileRecords = (projectId) => importProjectMobileRecords(projectId).then(() => updateState('awaitingJobs', true))

    return {
      syncMobileRecords, updateState
    }
  }, [])

  const internalApi = useMemo(() => {

    const fetchData = () => {
      getActiveJobs(projectId, 'ImportMobileRecordsJob')
        .then(res => api.updateState('awaitingJobs', res !== null))
    }

    const loadNotes = () => {
      getProjectMobileRecords(projectId).then(res => api.updateState('mobileRecords', res))
        .then(() => api.updateState('loading', false))

    }

    const updateSyncedAt = () => {
      void getProject(projectId).then(({ data: { synced_at: syncedAt } }) => {
        syncedAt && api.updateState('syncedAt', syncedAt)
      })
    }

    return { fetchData, loadNotes, updateSyncedAt }
  }, [])

  useInterval(async () => {
    if (state.awaitingJobs) {
      getActiveJobs(projectId, 'ImportMobileRecordsJob').then(res => {
        api.updateState('awaitingJobs', res !== null)
      })
    }
  }, 3000)

  useEffect(() => {
    void internalApi.fetchData()
  }, [])

  useEffect(() => {
    if (!state.awaitingJobs) {
      void internalApi.loadNotes()
      void internalApi.updateSyncedAt()
    }
  }, [state.awaitingJobs])

  return (
    <MobileRecordsApiContext.Provider value={api}>
      <MobileRecordsContext.Provider value={state}>
        {children}
      </MobileRecordsContext.Provider>
    </MobileRecordsApiContext.Provider>
  )
}

/* Custom Context Hooks */
export const useMobileRecordsContext = () => useContext(MobileRecordsContext)
export const useMobileRecordsApi = () => useContext(MobileRecordsApiContext)


