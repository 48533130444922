import React from 'react'

const HiddenFields = ({ userId, id, date, day_entry_id }) => (
  <>
    {id && <input type="hidden" name="id" value={id || ""} />}
    <input name="user_id" type="hidden" value={userId || ""} />
    <input name="date" type="hidden" value={date || ""} />
    <input name="day_entry_id" type="hidden" value={day_entry_id || ""} />
  </>
)

export default HiddenFields
