import { faArrowCircleLeft, faArrowCircleRight } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { useMemo, useState } from 'react'
import { useMobileRecordContext } from "../../../contexts/mobileRecordContext"
import Entry from "./entry"

const BoreholeDetails = ({ children }) => {
  const [showBoreholeDetails, setShowBoreholeDetails] = useState(false)
  const { mobileRecord, formTemplates } = useMobileRecordContext()
  const formTemplate = useMemo(() => formTemplates[mobileRecord.form_record_type], [mobileRecord, formTemplates])

  return (<>
      <div className='flex-row bg-gray-200 flex'>
        {showBoreholeDetails && <div className='flex flex-col w-full gap-y-4 p-2'>
          {formTemplate?.fields?.map(field => {
            const entry = mobileRecord?.entries.find(entry => entry.position === field.position) || {}
            if (field.name !== 'boreholeId') return <Entry key={`${field.id}-${entry.id}`} entry={entry} field={field}
                                                           map={[mobileRecord?.ref_guid]} />
          })}
        </div>}
        <div className='w-8 items-center flex flex-col hover:bg-gray-200 p-2 cursor-pointer gap-2'
             onClick={() => setShowBoreholeDetails(!showBoreholeDetails)}>
          <FontAwesomeIcon icon={showBoreholeDetails ? faArrowCircleLeft : faArrowCircleRight} size='lg' />
          <p className='uppercase whitespace-nowrap leading-none' style={{ writingMode: 'vertical-lr' }}>
            {formTemplate?.name}
          </p>
        </div>
      </div>
      {children}
    </>
  )
}

export default BoreholeDetails
