import React from 'react'
import Select from 'react-select'
import { inputClass } from '../../../utilities/stylesHelpers'
import { classNames } from '../../../utilities/utils'

const requiredStyle = (errors, value) => (errors && !value ? "border-red-500" : "")

const SelectedField = ({ label, name, options, value, onChange, required, errors }) => (
  <label className="w-full">
    {label} {required && <span className="text-red-700">*</span>}
    <Select
      options={options}
      onChange={onChange}
      className={classNames(inputClass, requiredStyle(errors, value))}
      value={value}
      placeholder={label}
      name={name}
      isClearable
    />
  </label>
)

export default SelectedField
